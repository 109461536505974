import { processResponse } from '~/site/behaviors/remote/process_response'
import { hideLoading, proceedWithPayment, showLoading } from '../common'

// Submits the form to our servers and handles the response ensuring form validity and pre-purchase agreements are signed.
// Either our server is successful in charging/setting up intents, in which case, we call onSuccess
//   onSuccess will contain all processor-specific logic.
// Or our server is unsuccessful, in which case we call onError
//   We also run the JSON through processResponse in case of failures which will handle generic validation errors
export const processFormSubmission = async (
  form,
  { getFormData, onSuccess, onError }
) => {
  try {
    // Make sure form is valid and pre-purchase agreements are signed
    if (!(await proceedWithPayment(form))) {
      hideLoading()
      return
    }

    // Get the form data to submit
    const formData = getFormData?.(form) || new FormData(form)

    // Show the loader.
    // Note: Don't call hideLoading in a finally block.
    //       Because onSuccess/onError might want to do other async things and show/hide loaders itself.
    showLoading()

    const response = await fetch(form.getAttribute('action'), {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/javascript',
      },
    })

    if (!response.ok) {
      throw 'Received error code: ' + response.status
    }

    const json = await response.json()

    if (json.success || json.attempt_3ds) {
      // No need to hide loading - success callbacks usually redirect to a different page.
      // If there's an error when submitting to the processor, the caller is responsible for hiding the loader
      onSuccess(json)
    } else {
      // Generic error handlers
      processResponse(json)
      if (json?.replace && json.replace['cart-errors-container']) {
        document.getElementById('cart-errors-container').scrollIntoView()
      }

      hideLoading()
      onError?.(json)
    }
  } catch (e) {
    hideLoading()
    alert('Sorry, we could not complete your request. ' + e)
  }
}

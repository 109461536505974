import {
  handleClientSideSubmitResult,
  initializeStripeElement,
  submitToStripe,
} from '../../../../common/components/stripe/index'

import {
  proceedWithPayment,
  showLoading,
  hideLoading,
  getBaseCartPath,
  onPaymentMethodChange,
} from '../common'
import { attachDynamicEvent } from '~/common/helpers/dom'
import { processFormSubmission } from './common'

/*
  Stripe payments can come in two forms:

  A new payment method.

  1. Initialize the Stripe UI (initializeForStripePaymentProcessor).
  2. On form submit, check if required Stripe inputs are filled
        a. If true, create a Transaction, then call the Stripe client-side code (submitWithNewStripe). This will handle
           validation and 3DS and stuff.
        b. If false, prevent form submission (avoid creating Simplero abandoned purchases), and show the stripe errors element.
  3. If Stripe is successful, it will go to the transaction's callback_url, which is a page which will wait until we
     receive word from Stripe that things worked.

  An existing payment method

  1. On form submit, attempt to charge server-side (submitWithSavedStripe). If that succeeds, go to the transaction's
     callback_url.
  2. If the server-side attempt failed, try Stripe's client-side code, which will handle 3DS (submitWithSavedStripe).
  3. If Stripe is successful, go to the transaction's callback_url.
*/

// Set up the UI for a new Stripe payment method
function initializeForStripePaymentProcessor(value) {
  showLoading()

  document
    .getElementById('stripe_card_element')
    .addEventListener('stripe:ready', () => {
      let bancontactNotice = document.getElementById('stripe_bancontact_notice')
      if (bancontactNotice) {
        bancontactNotice.style.display = 'none'
      }
      document.getElementById('stripe_fields').style.display = 'block'
      hideLoading()
    })

  value = value.replace(/pp-/, '')
  const [paymentProcessorId, paymentProcessorSubtype] = value.split('-')

  let formData = new FormData(
    document.querySelector('form.cart-checkout-payment')
  )
  formData.append('payment_method[payment_processor_id]', paymentProcessorId)

  if (paymentProcessorSubtype)
    formData.append(
      'payment_method[payment_processor_subtype]',
      paymentProcessorSubtype
    )

  formData.append('_method', 'post')

  fetch(getBaseCartPath() + '/stripe_setup', {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((json) => {
      let submitButton = getSubmitButton()
      submitButton.setAttribute('data-publishable-key', json.publishable_key)
      submitButton.setAttribute('data-client-secret', json.client_secret)
      submitButton.setAttribute('data-intent-id', json.intent_id)
      submitButton.setAttribute('data-intent-type', json.intent_type)

      if (paymentProcessorSubtype == 'bancontact') {
        let stripeFieldsElement = document.querySelector('#stripe_fields')
        if (stripeFieldsElement) {
          stripeFieldsElement.style.display = 'none'
        }
        document.getElementById('stripe_bancontact_notice').style.display =
          'block'
        hideLoading()
      } else {
        initializeStripeElement(
          json.publishable_key,
          json.client_secret,
          '#stripe_card_element'
        )
      }
    })
}

function chooseNewStripe(event) {
  initializeForStripePaymentProcessor(event.target.value)
}

function chooseMaybeNonStripe(event) {
  if (event.target.closest('.payment-processor-option-stripe') == null) {
    chooseNonStripe()
  }
}

function chooseNonStripe() {
  document
    .querySelectorAll('#stripe_fields, #stripe_bancontact_notice')
    .forEach((el) => (el.style.display = 'none'))
}

function submitIfWithNewStripe(event) {
  if (getSelectedStripeProcessorOption()) {
    submitWithNewStripe(event)
  }
}

function submitIfWithSavedStripe(event) {
  if (getSelectedSavedStripeOption()) {
    submitWithSavedStripe(event)
  }
}

async function submitWithNewStripe(event) {
  event.preventDefault()

  let submitButton = getSubmitButton()
  let form = event.target

  getErrorElement().style.display = 'none'

  const { intentId, intentType } = getIntentIdAndType()

  processFormSubmission(form, {
    getFormData: () => {
      let formData = new FormData(form)
      formData.append('intent_id', intentId)
      formData.append('intent_type', intentType)
      return formData
    },
    onSuccess: async (json) => {
      const error = await submitToStripe(
        submitButton.getAttribute('data-publishable-key'),
        submitButton.getAttribute('data-client-secret'),
        json.callback_url,
        intentType == 'setup',
        getSelectedSubtype(),
        submitButton.getAttribute('data-customer-name'),
        submitButton.getAttribute('data-customer-email'),
        '#stripe_card_element'
      )

      if (error) {
        let errorElement = getErrorElement()
        errorElement.querySelector('.error-message').innerText = error.message
        errorElement.style.display = 'block'
        errorElement.classList.remove('hidden') // hidden have !important, 'block' does not work in some cases
        hideLoading()
      }
    },
  })
}

function getErrorElement() {
  return document.getElementById('stripe_errors')
}

function getSubmitButton() {
  return document.querySelector(
    '.cart-checkout-payment .submit-payment, .cart-checkout-payment button[type="submit"]'
  )
}

export function getIntentIdAndType() {
  const button = getSubmitButton()
  return {
    intentId: button.dataset.intentId,
    intentType: button.dataset.intentType,
  }
}

// Attempt to charge server-side. If that fails, go client-side (for 3DS).
async function submitWithSavedStripe(event) {
  event.preventDefault()

  let submitButton = getSubmitButton()
  let form = event.target

  processFormSubmission(form, {
    onSuccess: async (json) => {
      let publishableKey = getSelectedSavedStripeOption().getAttribute(
        'data-publishable-key'
      )
      let error = await handleClientSideSubmitResult(
        json,
        publishableKey,
        null,
        submitButton.getAttribute('data-customer-name'),
        submitButton.getAttribute('data-customer-email')
      )
      if (error) {
        alert('Sorry, we could not complete your request. ' + error)
        hideLoading()
      }
    },
  })
}

function getSelectedStripeProcessorOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-processor-option-stripe input[type=radio]:checked, ' +
      ".cart-checkout-payment input[type=hidden][name='payment_method'].payment-processor-option-stripe"
  )
}

function getSelectedSavedStripeOption() {
  return document.querySelector(
    '.cart-checkout-payment .payment-method-option-stripe input[type=radio]:checked'
  )
}

function getSelectedSubtype() {
  let value = getSelectedStripeProcessorOption().value
  value = value.replace(/pp-/, '')
  const [_paymentProcessorId, paymentProcessorSubtype] = value.split('-')
  return paymentProcessorSubtype
}

onPaymentMethodChange(chooseMaybeNonStripe)
attachDynamicEvent(
  '.cart-checkout-payment .payment-processor-option-stripe input[type=radio]',
  'change',
  chooseNewStripe
)
attachDynamicEvent(
  'form.cart-checkout-payment',
  'submit',
  submitIfWithNewStripe
)
attachDynamicEvent(
  'form.cart-checkout-payment',
  'submit',
  submitIfWithSavedStripe
)
